<template>
    <div class="regions-list position-relative w-100">
       <!-- Preloader -->
        <div class="text-center my-5" v-show="loading">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-dark" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <!-- Users -->
            <div class="table-responsive" v-show="!loading">
                <table class="table table-striped table-sm">
                  <caption>{{ users.length }} Utilizadores</caption>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th class="w-25">Nome</th>
                      <th class="w-25">Email</th>
                      <th>Data Registo</th>
                      <th>Status</th>
                      <th>Edição</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in filteredUsers" v-if="filteredUsers">
                      <td>{{ user.id }}</td>
                      <td>{{ user.name }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.created_at | moment('D/M/Y') }}</td>
                      <td>
                        <span v-if="user.is_admin == 1" class="text-secondary">Administrador</span>
                        <span v-else-if="user.is_associado == 1" class="text-success">Associado Ativo</span>
                        <span v-else-if="user.is_associado == 0 && user.is_user == 0" class="text-danger">Associado Suspenso</span>
                        <span v-else-if="user.is_associado == 0 && user.is_user == 1" class="text-info">Utilizador Normal</span>
                      </td>
                      <td>
                        <button v-on:click="deleteUser(user.id)" class="btn btn-outline-danger btn-sm">Eliminar</button>
                        <a :href="'/users/' + user.id" class="btn btn-outline-secondary btn-sm">Ver Perfil</a>
                      </td>
                    </tr>
 
                  </tbody>
                </table>
            </div>
        <!-- /Users -->
    </div>
</template>

<script>

    import moment from 'moment';

    export default {
    data() {
        return {
            users: [],
            search: '',
            flash: '',
            loading: false
        }
    },
    created(){
        this.fetchUsers();
        this.$eventHub.$on('search-term', this.addSearch);
    },
    computed: {
        filteredUsers(id) {
          if (this.search) {
            return this.users
            .filter(user => {
              if (this.search.toLowerCase() == 'ativo') {
                return user.is_associado == 1
              }
              else if (this.search.toLowerCase() == 'normal') {
                return user.is_user == 1 && user.is_associado == 0
              }
              else if (this.search.toLowerCase() == 'suspenso') {
                return user.is_user == 0 && user.is_associado == 0 && user.is_admin == 0
              }
              else if (this.search.toLowerCase() == 'admin') {
                return user.is_admin == 1
              }
              else {
                return user.name.toLowerCase().includes(this.search.toLowerCase()) ||
                user.email.toLowerCase().includes(this.search.toLowerCase()) ||
                user.id == this.search
              }
            
            })
          }
          else {
            return this.users; 
          }
        }
    },
    methods: {
        fetchUsers(){
            this.loading = true;
            axios.get('/api/listusers')
            .then((response) => (
              this.users = response.data.users,
              this.loading = false
            ))
            .catch((err) => {
                console.log(err)
            })
        },
        addSearch(search) {
            this.search = search
        },
        deleteUser(id) {
          if(confirm("Tem certeza?")){
            axios.delete('/api/listusers/' + id)
            .then((response) => (
              this.users = this.users.filter(user => user.id != id),
              alert('Utilizador eliminado')
            ))
            .catch(error => {
              console.log(error);
            })
          }
        }
    }
}
</script>