<template>
    <div class="agencias-list position-relative w-100">
        <div class="container my-5 py-5">
            <div class="heading mb-4">
                <h2 :class="'section-title text-' + propsColor +  ' text-uppercase mb-2 font-weight-bold'">Agências Funerárias por Região</h2>
                <p :class="'lead text-' + propsColor ">Aqui, encontra todas as agências funerárias que lhe garantem um serviço de qualidade,<br>no respeito dos princípios éticos que a profissão encerra.</p>
            </div>
            <div data-aos="flip-up" data-aos-easing="ease-in-sine">
                <form class="form-row">
                    <div class="form-group col-md">
                        <input class="form-control form-control-lg" type="text" name="search" v-model="search" placeholder="Procurar por...">
                    </div>
                    <div class="form-group col-md-2">
                        <select class="form-control form-control-lg" name="distrito" v-model="distrito" ref="distrito" v-on:change="onChange">
                            <option value="">Distrito</option>
                            <option v-for="distrito in filterDistritos" :value="{ name: distrito.name, code: distrito.code }">
                                        {{ distrito.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-2">
                        <select class="form-control form-control-lg" name="concelho" v-model="concelho" v-on:change="onChange">
                            <option value="">Concelho</option>
                            <option v-for="concelho in filterConcelhos" :value="{ name: concelho.name, code: concelho.code }">
                                        {{ concelho.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-2">
                        <select class="form-control form-control-lg" name="freguesia" v-model="freguesia" v-on:change="onChange">
                            <option value="">Freguesia</option>
                            <option v-for="freguesia in filterFreguesias" :value="{ name: freguesia.name, code: freguesia.code }">
                                        {{ freguesia.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-2">
                        <input class="form-control form-control-lg btn btn-dark btn-lg text-uppercase" type="button" name="limpar" v-on:click="resetSearch()" value="Limpar">
                    </div>
                </form>  
 
            </div>
          
            <div class="row mt-4" v-if="filteredAssociados">
                <div v-for="agencia in orderedAssociados" class="col-md-6 same-height mb-4">
                    <div class="card w-100 shadow-sm">
                        <div class="card-body mr-auto">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="assoc-logo" v-if="agencia.logo != ''" :style="'width:100%;height:80px;background: url(/storage/agencias/' + agencia.logo + ') no-repeat;'"></div>
                                    <div class="assoc-logo" v-else="agencia.logo" style="'width:100%;height:80px;background: url(/img/associados/default.jpg) no-repeat;background-size: contain;background-position: center;"></div>
                                    <p class="lightgrey text-center"><small>{{ agencia.dgae }}</small></p>
                                </div>
                                <div class="col-sm-8">
                                    <h5 class="card-title mb-1 font-weight-bold">{{ agencia.name | capitalize }}</h5>
                                    <p><small>{{ agencia.morada | capitalize }}<br>
                                        {{ agencia.cod_postal | capitalize }}<br>
                                        {{ agencia.distrito | capitalize }} - {{ agencia.concelho | capitalize }}</small></p>
                                    <hr class="mt-1 mb-1">
                                    <small class="text-muted" v-if="agencia.tel_1">Tel: {{ agencia.tel_1 | phone }}</small> <small class="text-muted" v-if="agencia.tel_2"> - Tel: {{ agencia.tel_2 | phone }}</small> <small class="text-muted" v-if="agencia.fax"><br>Fax: {{ agencia.fax | phone }}</small>
                                        <hr class="mt-1 mb-1">
                                    <small><a v-if="agencia.email" :href="'mailto:' + agencia.email" class="card-link">{{ agencia.email }}</a><a v-if="agencia.site_url" class="card-link" :href="'http://' + agencia.site_url" target="_blank"><br>{{ agencia.site_url }}</a></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
   .form-group {
        margin-bottom: 0;
   }
   @media screen and (max-width: 768px) {
        .form-group {
            margin-bottom: 1em;
        }
    }
   .assoc-logo {
    background-size: contain !important;
    background-position: center !important;
   }
   .btn-light {
    background: #e2e6ea linear-gradient(180deg, #e5e9ed, #e2e6ea) repeat-x;
   }
</style>

<script>
    export default {
    props: ['textcolor'],
    data() {
        return {
            users: [],
            search: '',
            regioes: [],
            distrito: '',
            concelho: '',
            freguesia: '',
            distritoName: '',
            concelhoName: '',
            freguesiaName: '',
            propsColor: ''
        }
    },
    created(){
        this.fetchRegioes();
        this.fetchAssociados();
    },
    mounted() {
        this.propsColor = this.textcolor;
    },
    computed: {  
        filteredAssociados () {
            if (this.search || this.distrito) {
                return this.users
                .filter(agencia => {
                    return agencia.name.toLowerCase().includes(this.search.toLowerCase()) || agencia.cod_postal.toLowerCase().includes(this.search.toLowerCase()) || agencia.morada.toLowerCase().includes(this.search.toLowerCase()) || agencia.distrito.toLowerCase().includes(this.search.toLowerCase()) || agencia.concelho.toLowerCase().includes(this.search.toLowerCase()) || agencia.freguesia.toLowerCase().includes(this.search.toLowerCase())
                })
                .filter(agencia => {
                    return agencia.distrito.toLowerCase().includes(this.distritoName.toLowerCase())
                })
                .filter(agencia => {
                    return agencia.concelho.toLowerCase().includes(this.concelhoName.toLowerCase())
                })
                .filter(agencia => {
                    const freg = agencia.freguesia.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
                    const fregName = this.freguesiaName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
                    return freg.includes(fregName)
                })

            }
        },
        filterDistritos () {
            return this.regioes.filter(({ level }) => level === 1)
        },
        filterConcelhos () {
          const codeCheck = new RegExp(`^${this.distrito.code}\\d{2}$`)
          return this.regioes.filter(({ level, code }) =>
              level === 2 && codeCheck.test(code))
        },
        filterFreguesias () {
          const codeCheck = new RegExp(`^${this.concelho.code}\\d{2}$`)
          return this.regioes.filter(({ level, code }) =>
              level === 3 && codeCheck.test(code))
        },
        orderedAssociados () {
            return _.orderBy(this.filteredAssociados, 'id')
        }
    },
    methods: {
        onChange(){
            if (this.distrito.name) {
                this.distritoName = this.distrito.name
            }
            else{
                this.distritoName = '',
                this.concelhoName = '',
                this.freguesiaName = ''
            }

            if (this.concelho.name) {
               this.concelhoName = this.concelho.name
            }
            else{
                this.concelhoName = '',
                this.freguesiaName = ''
            }

            if (this.freguesia.name) {
                this.freguesiaName = this.freguesia.name
            }
            else{
                this.freguesiaName = ''
            }
        },
        fetchRegioes(){
            axios.get('/json/regioes.json')
            .then((response) => (
                this.regioes = response.data
                ))
            .catch((err) => {
                console.log(err)
            })
        },
        fetchAssociados(){
            axios.get('/agencias')
            .then((response) => (
                this.users = response.data
                ))
            .catch((err) => {
                console.log(err)
            })
        },
        resetSearch() {
            this.search = '',
            this.distrito = '',
            this.concelho = '',
            this.freguesia = '',
            this.distritoName = '',
            this.concelhoName = '',
            this.freguesiaName = ''
        }
    },
    filters: {
        capitalize (value) {
            if (!value) return ''
            value = value.toString().toLowerCase()
            return value.replace(/(^|\s)\S/g, l => l.toUpperCase())
        },
        phone(phone) {
            if (!phone) return ''
            return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
        },
        slugify(value) {
          value = value.replace(/^\s+|\s+$/g, ''); // trim
          value = value.toLowerCase();

          // remove accents, swap ñ for n, etc
          var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
          var to   = "aaaaaeeeeeiiiiooooouuuunc------";
          for (var i=0, l=from.length ; i<l ; i++) {
            value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
          }

          value = value.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

          return value;
        }
    }
}
</script>