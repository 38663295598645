<template>
    <div class="regions-list position-relative w-100">
        <div class="row my-4">
            <div class="form-group col-md-6">
                <select class="form-control form-control-lg" name="make" id="make" v-model="cat">
                    <option :value="null" selected>Categorias</option>
                    <option v-for="cat in categories" v-bind:value="cat.id">{{ cat.name }}</option>
                </select>
            </div>
             <div class="form-group col-md-6">
                <input class="form-control form-control-lg" type="text" name="search" v-model="search" placeholder="Procurar por...">
            </div>
        </div>
        <table class="table table-striped table-sm" v-if="cat || search">
            <thead>
                <tr>
                    <th>Título</th>
                    <th>Documento</th>
                    <th v-if="is_admin == true"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="doc in filteredDocs">
                    <td>{{ doc.title }}</td>
                    <td><a :href="'/storage/' + doc.doc" target="_blank">Ver/Download</a></td>
                    <td v-if="is_admin == true">
                        <button v-on:click.prevent="deleteDoc(doc.id)" class="btn btn-outline-danger btn-sm">Eliminar</button>
                        <a :href="'/docs/' + doc.id + '/edit'" class="btn btn-outline-secondary btn-sm">Editar</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <h2 v-else class="lead text-center text-muted my-5">Selecione categoria ou procure documento</h2>
    </div>
</template>

<style scoped>

</style>

<script>
    export default {
    props: ['user'],
    data() {
        return {
            docs: [],
            categories: [],
            cat: null,
            doc: null,
            search: '',
            currentuser: this.user,
            is_admin: this.user.is_admin
        }
    },
    created(){
        this.fetchDocs();
    },
    computed: {
        filteredDocs() {
            if (this.search) {
                return this.docs
                .filter(doc => {
                    return doc.title.toLowerCase().includes(this.search.toLowerCase())
                })
            }
            if (this.cat) {
                return this.docs
                .filter(doc => {
                    return this.cat == doc.doc_category_id
                })
            }
        }
    },
    methods: {
        fetchDocs(){
            axios.get('/api/listdocs')
            .then((response) => (
                this.docs = response.data.docs,
                this.categories = response.data.categories
            ))
            .catch((err) => {
                console.log(err)
            })
        },
        deleteDoc(id) {
            if(confirm("Tem certeza?")){
                axios.delete('/api/listdocs/' + id)
            .then((response) => (
                this.docs = this.docs.filter(doc => doc.id != id),
                alert('Documento eliminado')
            ))
            .catch(error => {
                console.log(error);
            })
          }
        }
    }
}
</script>