
require('./bootstrap');

window.Vue = require('vue');

const feather = require('feather-icons');
feather.replace();

const datepicker = require('bootstrap-datepicker');

require('../../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt.min.js');


Vue.component('associados', require('./components/AssociadosComponent.vue').default);
Vue.component('docs', require('./components/DocsComponent.vue').default);
Vue.component('search', require('./components/SearchComponent.vue').default);
Vue.component('posts', require('./components/PostsComp.vue').default);
Vue.component('users', require('./components/UsersComp.vue').default);
Vue.component('regions', require('./components/RegionsComp.vue').default);



const moment = require('moment')
require('moment/locale/pt')
 
Vue.use(require('vue-moment'), {
    moment
})


Vue.prototype.$eventHub = new Vue();

const app = new Vue({
    el: '#app'
});

$(document).ready(function () {

  var editor_config = {
    path_absolute : "/",
    branding: false,
    menubar: false,
    language_url: '/vendor/tinymce/langs/pt_PT.js',
    selector: '.tiny-editor',
    plugins: ['fullscreen lists link image media'],
    toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline removeformat | alignleft aligncenter alignright | bullist numlist | indent outdent | link | image | fullscreen',
    relative_urls: false,
    file_browser_callback : function(field_name, url, type, win) {
      var x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
      var y = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight;

      var cmsURL = editor_config.path_absolute + 'laravel-filemanager?field_name=' + field_name;
      if (type == 'image') {
        cmsURL = cmsURL + "&type=Images";
      } else {
        cmsURL = cmsURL + "&type=Files";
      }

      tinyMCE.activeEditor.windowManager.open({
        file : cmsURL,
        title : 'Filemanager',
        width : x * 0.8,
        height : y * 0.8,
        resizable : "yes",
        close_previous : "no"
      });
    }
  };

  tinymce.init(editor_config);

  var editor_config_ads = {
    path_absolute : "/",
    selector: '.tiny-editor-no-image',
    branding: false,
    menubar: false,
    language_url: '/vendor/tinymce/langs/pt_PT.js',
    plugins: ['fullscreen lists'],
    toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline removeformat | alignleft aligncenter alignright | bullist numlist | indent outdent | fullscreen',
    relative_urls: false,
    file_browser_callback : function(field_name, url, type, win) {
      var x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
      var y = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight;

      var cmsURL = editor_config.path_absolute + 'laravel-filemanager?field_name=' + field_name;
      if (type == 'image') {
        cmsURL = cmsURL + "&type=Images";
      } else {
        cmsURL = cmsURL + "&type=Files";
      }

      tinyMCE.activeEditor.windowManager.open({
        file : cmsURL,
        title : 'Filemanager',
        width : x * 0.8,
        height : y * 0.8,
        resizable : "yes",
        close_previous : "no"
      });
    }
  };

  tinymce.init(editor_config_ads);


  AOS.init({mirror: true});


    // LightBox
    var $lightbox = $('#lightbox');
    $('[data-target="#lightbox"]').on('click', function(event) {
      var $img = $(this).find('img'), 
      src = $img.attr('src'),
      alt = $img.attr('alt');

      $lightbox.find('.close').addClass('hidden');
      $lightbox.find('img').attr('src', src);
      $lightbox.find('img').attr('alt', alt);
      $lightbox.find('img').css( {'width': '50vw'} );
      $lightbox.find('.modal-dialog').css({'max-width': '50vw', 'top': '15vh'});
    });
    
    $lightbox.on('shown.bs.modal', function (e) {
      var $img = $lightbox.find('img');

      $lightbox.find('.close').removeClass('hidden');
    });

    // Sticky Header
    $(window).scroll(function(){
      var target = $('.site-header'),
      scroll = $(window).scrollTop(),
      main = $('main'),
      height = $(target).innerHeight(),
      logo = $('.navbar .navbar-brand img');

      target.css('top', - height);

      if (scroll >= height) target.addClass('sticky'), main.css('padding-top', height), logo.css('width', '6vw');
      else target.removeClass('sticky'), main.css('padding-top', 0), logo.css('width', '15vw');
    });


    $('#datepicker').datepicker({
      language: 'pt',
      format: 'dd-mm-yyyy'
    });

     // Nome do ficheiro no input type file
    $('.custom-file-input').on('change',function(){
      var fileName = $(this).val();
      $(this).next('.custom-file-label').html(fileName);
    });

  });

if(window.location.pathname == '/contactos') {
//Google maps
var map;

var styleArray = [
{
  featureType: "all",
  stylers: [
  { saturation: -80 }
  ]
},{
  featureType: "road.arterial",
  elementType: "geometry",
  stylers: [
  { hue: "#7570b3" },
  { saturation: 50 }
  ]
},{
  featureType: "poi.business",
  elementType: "labels",
  stylers: [
  { visibility: "off" }
  ]
}
];

var porto = '<strong class="text-uppercase font-weight-bold">Delegação Norte</strong><br>Rua do Bonjardim, 652 - R/C<br>4000-118 Porto<br><br><a href="https://www.google.com/maps/place/R.+do+Bonjardim+652,+4000-124+Porto,+Portugal/@41.1524335,-8.6106442,17z/data=!3m1!4b1!4m6!3m5!1s0xd2464fbadb83291:0x7f2d5effb38c8bbf!8m2!3d41.1524295!4d-8.6080693!16s%2Fg%2F11csl571c_?hl=en&entry=ttu&g_ep=EgoyMDI0MDgyNi4wIKXMDSoASAFQAw%3D%3D" target="_blank">Obter direções</a>';
var lisboa = '<strong class="text-uppercase font-weight-bold">Sede Lisboa</strong><br>Rua Cidade de Quelimane, 3 A<br>1800-121 Lisboa<br><br><a href="https://www.google.com/maps/place/R.+Cidade+de+Quelimane+3A,+1800-121+Lisboa,+Portugal/@38.7651928,-9.1225253,17z/data=!3m1!4b1!4m6!3m5!1s0xd193237121e6443:0x258664941a8518bf!8m2!3d38.7651886!4d-9.1199504!16s%2Fg%2F11c5n4rw2k?hl=en&entry=ttu&g_ep=EgoyMDI0MDgyNi4wIKXMDSoASAFQAw%3D%3D" target="_blank">Obter direções</a>';
var faro = '<strong class="text-uppercase font-weight-bold">Delegação Sul</strong><br>Rua do Alportel, 153 - 1R<br>8000-292 Faro<br><br><a href="https://www.google.com/maps/place/R.+do+Alportel+153,+8000-291+Faro/@37.0236,-7.9352014,17z/data=!3m1!4b1!4m6!3m5!1s0xd0552c8d0c14b55:0xf420f5a5c2b17b89!8m2!3d37.0235957!4d-7.9326265!16s%2Fg%2F11csn926m6?hl=en&entry=ttu&g_ep=EgoyMDI0MDgyNi4wIKXMDSoASAFQAw%3D%3D" target="_blank">Obter direções</a>';
var coimbra = '<strong class="text-uppercase font-weight-bold">Delegação Centro</strong><br>Rua Dr.Manuel Rodrigues 25<br>3000-258 Coimbra<br><br><a href="https://www.google.com/maps/place/R.+Dr.+Manuel+Rodrigues+25,+3000-268+Coimbra,+Portugal/@40.2135401,-8.4351618,17z/data=!3m1!4b1!4m6!3m5!1s0xd22f904fb46dbf9:0x9591cb06b7f89e3a!8m2!3d40.213536!4d-8.4325869!16s%2Fg%2F11d_9f44vt?hl=en&entry=ttu&g_ep=EgoyMDI0MDgyNi4wIKXMDSoASAFQAw%3D%3D" target="_blank">Obter direções</a>';

var locations = [
    [porto, 41.1524295,-8.610258],
    [lisboa, 38.7651892, -9.1221416],
    [faro, 37.0235957, -7.9348152],
    [coimbra, 40.2135401, -8.4351618]
];

var map = new google.maps.Map(document.getElementById('map-canvas'), {
    zoom: 6,
    center: new google.maps.LatLng(38.7651892, -9.1221416),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: styleArray,
    scrollwheel: false,
    zoomControl:true,
    mapTypeControl:true
    });

var contentString = '';

var marker, i;

for (i = 0; i < locations.length; i++) {  
    marker = new google.maps.Marker({
        position: new google.maps.LatLng(locations[i][1], locations[i][2]),
        map: map
    });

      google.maps.event.addListener(marker, 'click', (function(marker, i) {
        return function() {
          infowindow.setContent(locations[i][0]);
          infowindow.open(map, marker);
        }
      })(marker, i));

    var infowindow = new google.maps.InfoWindow({
    content: contentString
    });
}
}
