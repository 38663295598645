<template>
    <div class="regions-list position-relative w-100">
        <div class="form-row">
           <div class="form-group col-md-4">
            <select class="form-control form-control-lg" name="distrito" v-model="distrito" v-on:change="changeDistrito">
                
                <option v-if="selectedAdDistrito == null || selectedAgenciaDistrito == null" value="" disabled>Distrito</option>
                <option v-else-if="selectedAdDistrito" selected value="" disabled>{{selectedAdDistrito}}</option>
                <option v-else-if="selectedAgenciaDistrito" selected value="" disabled>{{selectedAgenciaDistrito}}</option>
                <option else value="" disabled>Distrito</option>
               
                <option v-for="distrito in filterDistritos" :value="distrito.name" :data-dist="distrito.code">
                    {{ distrito.name }}
                </option>
            </select>
        </div>
        <div class="form-group col-md-4">
            <select class="form-control form-control-lg" name="concelho" v-model="concelho" v-on:change="changeConcelho">

                <option v-if="selectedAdConcelho == null || selectedAgenciaConcelho == null" value="" disabled>Concelho</option>
                <option v-else-if="selectedAdConcelho" selected value="" disabled>{{selectedAdConcelho}}</option>
                <option v-else-if="selectedAgenciaConcelho" selected value="" disabled>{{selectedAgenciaConcelho}}</option>
                <option else value="" disabled>Concelho</option>


                <option v-for="concelho in filterConcelhos" :value="concelho.name" :data-conc="concelho.code">
                    {{ concelho.name }}
                </option>
            </select>
        </div>
        <div class="form-group col-md-4">
            <select class="form-control form-control-lg" name="freguesia" v-model="freguesia">
                <option v-if="selectedAdFreguesia == null || selectedAgenciaFreguesia == null" value="" disabled>Freguesia</option>
                <option v-else-if="selectedAdFreguesia" selected value="" disabled>{{selectedAdFreguesia}}</option>
                <option v-else-if="selectedAgenciaFreguesia" selected value="" disabled>{{selectedAgenciaFreguesia}}</option>
                <option else value="" disabled>Freguesia</option>


                <option v-for="freguesia in filterFreguesias" :value="freguesia.name">
                    {{ freguesia.name }}
                </option>
            </select>
        </div>
    </div>  
</div>
</template>

<style scoped>

</style>

<script>
    export default {
        props: ['ad', 'agencia'],
        data() {
            return {
                regioes: [],
                distrito: '',
                concelho: '',
                freguesia: '',
                distritoCode: '',
                concelhoCode: '',
                selectedAdDistrito: '',
                selectedAdConcelho: '',
                selectedAdFreguesia: '',
                selectedAgenciaDistrito: '',
                selectedAgenciaConcelho: '',
                selectedAgenciaFreguesia: ''
            }
        },
        created(){
            this.fetchDistritos();
        },
        mounted(){
            if (this.ad != null) {
                this.selectedAdDistrito = this.ad.distrito;
                this.selectedAdConcelho = this.ad.concelho;
                this.selectedAdFreguesia = this.ad.freguesia;
            }
            if (this.agencia != null) {
                this.selectedAgenciaDistrito = this.agencia.distrito;
                this.selectedAgenciaConcelho = this.agencia.concelho;
                this.selectedAgenciaFreguesia = this.agencia.freguesia;
            }
        },
        computed: {  
            filterDistritos () {
                return this.regioes
                .filter(({ level }) => level === 1)
            },
            filterConcelhos () {
                const codeCheck = new RegExp(`^${this.distritoCode}\\d{2}$`)
                return this.regioes
                .filter(({ level, code }) =>
                level === 2 && codeCheck.test(code))
            },
            filterFreguesias () {
                const codeCheck = new RegExp(`^${this.concelhoCode}\\d{2}$`)
                return this.regioes
                .filter(({ level, code }) =>
                level === 3 && codeCheck.test(code))
            }
        },
        methods: {
            fetchDistritos(){
                axios.get('/json/regioes.json')
                .then((response) => (
                    this.regioes = response.data        
                    ))
                .catch((err) => {
                    console.log(err)
                })
            },
            changeDistrito(e){
                if (this.distrito != '') {
                    this.distritoCode = e.target.options[e.target.options.selectedIndex].dataset.dist
                }
            },
            changeConcelho(e){
                if (this.concelho != '') {
                     this.concelhoCode = e.target.options[e.target.options.selectedIndex].dataset.conc
                }
            }
        }
    }
</script>