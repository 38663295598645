<template>
    <div class="form-group">
        <input class="form-control form-control-lg" type="text" name="search" v-model="search" placeholder="Procurar por..." v-on:input="searchTerm">
    </div>
</template>

<script>
    export default {
    data() {
        return {
            search: ''
        }
    },
    methods: {
        searchTerm(search){
            this.$eventHub.$emit('search-term', this.search);
        }
    }
}
</script>