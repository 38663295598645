import { render, staticRenderFns } from "./PostsComp.vue?vue&type=template&id=39c54973"
import script from "./PostsComp.vue?vue&type=script&lang=js"
export * from "./PostsComp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports