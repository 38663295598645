<template>
    <div class="regions-list position-relative w-100">
        <!-- Notícias -->
        <div v-for="post in filteredPosts" class="card mb-4 shadow-sm" v-if="filteredPosts" v-show="!loading">
            <img :src="'/storage/posts/' + post.image" class="card-img-top img-fluid" :alt="post.title">
            <div class="card-body position-relative">
                <h4 class="mb-0 text-uppercase font-weight-bold">
                    <a class="text-dark" :href="'/noticias/' + post.id">{{ post.title }}</a>
                </h4>
                <p class="text-muted"><small>há {{ post.created_at | moment("from", "now", true) }}</small></p>
            </div>
        </div>

        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item"><a class="page-link" href="#" v-on:click="fetchPaginatePosts(pagination.prev_page_url)" :disabled="!pagination.prev_page_url">anterior</a></li>
            <li class="page-item"><a class="page-link" href="#" v-on:click.prevent>Página {{ pagination.current_page }} de {{ pagination.last_page }}</a></li>
            <li class="page-item"><a class="page-link" href="#" v-on:click="fetchPaginatePosts(pagination.next_page_url)" :disabled="!pagination.next_page_url">seguinte</a></li>
          </ul>
        </nav>
    </div>
</template>

<script>

    import moment from 'moment';

    export default {
        props: ['postcategory'],
    data() {
        return {
            posts: [],
            search: null,
            month: null,
            category: null,
            url: '/api/listposts',
            pagination: [{
                current_page: '',
                last_page: '',
                next_page_url: '',
                prev_page_url: ''
            }],
            loading: false
        }
    },
    mounted() {
        var url = new URL(window.location.href);
        var month = url.searchParams.get("monthid");
        this.month = month;
        this.category = this.postcategory;
    },
    created(){
        this.fetchPosts();
        this.$eventHub.$on('search-term', this.addSearch);
    },
    computed: {
        filteredPosts(id) {
            if(this.search != null) {
                return this.posts
                .filter(post => {
                    return post.title.toLowerCase().includes(this.search.toLowerCase())
                })
            }
            else if(this.month != null) {
                return this.posts
                .filter(post => {      
                    return moment(post.created_at).format('M') == this.month;
                })
            }
            else if (this.category != null) {
                return this.posts
                 .filter(post => {      
                    return post.category_id == this.category
                })
            }
            else {
                return this.posts
            }
        }
    },
    methods: {
        fetchPosts(){
            this.loading = true;
            axios.get(this.url)
            .then((response) => (
                this.posts = response.data.posts.data,
                this.pagination = response.data.pagination,
                this.pagination.current_page = response.data.posts.current_page,
                this.pagination.last_page = response.data.posts.last_page,
                this.pagination.next_page_url = response.data.posts.next_page_url,
                this.pagination.prev_page_url = response.data.posts.prev_page_url,
                console.log(response.data.pagination),
                this.loading = false
            ))
            .catch((err) => {
                console.log(err)
            })
        },
        addSearch(search) {
            this.search = search
        },
        fetchPaginatePosts(url) {
            this.url = url,
            this.fetchPosts()
        }   
    }
}
</script>